<template>
    <div ref="dropdownContainer">
        <img :src="selectedLanguage" class="flag" @click="toggleCountryDropBox" />
        <Transition name="fade">
            <div v-if="showCountryDropBox" class="language-list-container">
                <div v-for="locale in $i18n.locales" :key="locale.iso" class="language-list"
                    @click="changeLanguage(locale.code)">
                    <img :src="getImagePath(locale.code)" class="flag" />
                    <div>{{ locale.name }}</div>
                </div>
            </div>
        </Transition>
    </div>
</template>

<script lang="ts">
import type ts from 'typescript';
import { useGuestStore } from '../store/guestStore';

const gStore = useGuestStore();

export default {
    name: 'CountryDropBox',

    data() {
        return {
            showCountryDropBox: false,
        }
    },
    computed: {
        selectedLanguage(): string {
            return this.getImagePath(gStore.languageCode);
        }
    },
    methods: {
        toggleCountryDropBox() {
            this.showCountryDropBox = !this.showCountryDropBox;
        },
        changeLanguage(languageCode: string) {
            gStore.changeLanguage(languageCode);
            this.showCountryDropBox = false;
        },
        getImagePath(languageCode: string): string {
            // @ts-ignore
            return new URL(`../assets/images/flags/${languageCode}.jpg`, import.meta.url).href;
        },
        handleClickOutside(event: MouseEvent) {
            const target = event.target as Node;
            if (this.$refs.dropdownContainer && !this.$refs.dropdownContainer.contains(target)) {
                this.showCountryDropBox = false;
            }
        }
    },
    mounted() {
        document.addEventListener('touchstart', this.handleClickOutside);
    },
    beforeUnmount() {
        document.removeEventListener('touchstart', this.handleClickOutside);
    }
}
</script>

<style scoped>
/* Default styles for LTR */
.language-list-container {
    position: absolute;
    top: 100%;
    right: 2rem;
    /* Positions container 2rem from the right */
    display: flex;
    flex-direction: column;
    background-color: #00000077;
    padding: 10px;
    border-radius: 16px 3px 16px 16px;
    gap: 0.75rem;
    width: 9rem;
    pointer-events: all;
    z-index: 1000;
}

.language-list {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    color: white;

    background-color: #222222c1;
    border-radius: 5px;
    padding: 0.25em;
}

.flag {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    border: 1px solid white;
    margin-right: 0.5rem;
    pointer-events: all;
}

.language-name {
    white-space: nowrap;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

/* RTL Overrides */
[dir="rtl"] .language-list-container {
    right: auto;
    left: 2rem;
    border-radius: 3px 16px 16px 16px;
}

[dir="rtl"] .flag {
    margin-right: 0;
    margin-right: 0.5rem;
}

[dir="rtl"] .language-list {
    flex-direction: row-reverse;
}
</style>
